import React, { useState } from "react"
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"
import '../styles/date.css'
import Refresh from "./imageComponents/refresh"

const DateComponent = ({selectedDate, onClick, description}) => {
  const [rotateNumber, setRotateNumber] = useState(0)

  const reset = () => {
    onClick()
    const updateRotate = rotateNumber + 360;
    setRotateNumber(updateRotate);
  }

  return (
          <div className='date-container'>
            <DatePicker selected={selectedDate} onChange={(date) => onClick(date)} className='date-input'/>
            <div className='reset' onClick= {() => reset()} style={{transform: `rotate(${rotateNumber}deg)`}}>
              <Refresh />
            </div>
            <div className='label'>
              {description}
            </div>
          </div>
  )
}

export default DateComponent;