import React, { useState } from "react"
import {
  differenceInWeeks,
  format,
  getISOWeek,
  startOfYear,
  toDate
} from 'date-fns'

import Layout from "../components/layout"
import Seo from "../components/seo"
import '../styles/style.css'
import '../styles/home.css'
import DateComponent from "../components/date.js"
import {
  daysInYear,
  getDayOfTheYear,
  getFirstDay,
  getLastDay,
  getYearLength,
  needsNewDate
} from "../functions/helper"

const IndexPage = () => {
  const currentDayOfTheYear =  new Date();
  const startOfTheYear = toDate(startOfYear(currentDayOfTheYear));
  const weekFirstDay = getFirstDay(startOfTheYear);
  const [firstDay, setFirstDay] = useState(weekFirstDay)
  const [lastDay, setLastDay] = useState(getLastDay(startOfTheYear))
  const [startDate, setStartDate] = useState(startOfTheYear)
  const [currentDate, setCurrentDate] = useState(currentDayOfTheYear)
  const [currentWeek, setCurrentWeek] = useState(getISOWeek(currentDayOfTheYear))
  const [currentYear, setCurrentYear] = useState(getYearLength(startOfTheYear))


  const updateStartDate = (date = startOfTheYear) => {
    const firstDayOfYear = getFirstDay(date);
    const lastDayOfYear = getLastDay(date);
    const newCurrentDate = needsNewDate(date, currentDate, false);

    if(newCurrentDate) {
      setCurrentDate(newCurrentDate)
    }

    setStartDate(date)
    setFirstDay(firstDayOfYear)
    setCurrentWeek(differenceInWeeks(newCurrentDate || currentDate, firstDayOfYear) + 1)
    setCurrentYear(getYearLength(date))
    setLastDay(lastDayOfYear)
  }

  const updateCurrentDate = (date = currentDayOfTheYear) => {
    setCurrentDate(date)
    let startOfYear = firstDay;
    let newYear = needsNewDate(startDate, date, true);

    if(newYear) {
      startOfYear = getFirstDay(newYear);
      setStartDate(newYear)
      setFirstDay(startOfYear)
      setLastDay(getLastDay(newYear))
    }


    setCurrentYear(getYearLength(newYear || startDate))
    setCurrentWeek(differenceInWeeks(date, startOfYear) + 1)
  }

  return (
    <Layout>
      <Seo title="Week" />
      <div className='card calculator'>
        <div className="container row-container row-space-between date-buttons">
          <DateComponent selectedDate={startDate} onClick={updateStartDate} description='Start Date' />
          <DateComponent selectedDate={currentDate} onClick={updateCurrentDate} description='End Date' />
        </div>
        <div className="results container">
          <div className="column-container">
            <div className="header">
              Week
            </div>
            <div className="currentWeek header">
              {currentWeek}
            </div>
            <div className="header align-right">
              Of {currentYear}
            </div>
          </div>
        </div>
      </div>
      <div className='card more-data'>
        <h2 className='header'>
          Additional Info
        </h2>
        <div className='row-container row-space-between list-items'>
          <div> First Day:</div>
          <div> {format(firstDay, 'MM/dd/yy')}</div>
        </div>
        <div className='row-container row-space-between list-items'>
          <div> Last Day:</div>
          <div> {format(lastDay, 'MM/dd/yy')}</div>
        </div>
        <div className='row-container row-space-between list-items'>
          <div> Day of The Year:</div>
          <div> {getDayOfTheYear(firstDay, currentDate)}/{currentYear * 7}</div>
        </div>
        <div className='row-container row-space-between list-items'>
          <div> Day of Current Year:</div>
          <div> {getDayOfTheYear(startDate, currentDate)}/{daysInYear(startDate)}</div>
        </div>
      </div>
      <div className='card description'>
        <h2 className='header'>
          Week of the Year Calculation
        </h2>
        <p>
          The numbers above are calculated using the ISO week date system. This system is used broadly by governments and private businesses. There are other week systems that vary but they work similarly.
        </p>
        <p>
          The ISO week systems first day of the week is Monday and the first week of the year is the first week that has 4 or more days from that year in it. That means the week year can start before the 1st or after.
        </p>
      </div>
    </Layout>
  )
}

export default IndexPage
