import {
  add,
  differenceInDays,
  getDay,
  isSameDay,
  isThursday,
  isWednesday,
  startOfISOWeek,
  sub,
  toDate,
} from 'date-fns'

export const getFirstDay = (date) => {
  const dayOfWeek = getDayOfWeek(date);
  let firstDay;

  if(dayOfWeek && dayOfWeek <= 4) {
    firstDay = startOfISOWeek(date);
  } else {
    firstDay = startOfISOWeek(add(date, {weeks: 1}));
  }

  return toDate(firstDay);
}

export const daysInYear = (startDate) => {
  return differenceInDays(add(startDate, {years: 1}), startDate);
}
export const getYearLength = (startDate) => {
  const daysInTheYear = daysInYear(startDate);

  if( isThursday(startDate) || (daysInTheYear === 366 && isWednesday(startDate))) {
    return 53;
  } else {
    return 52;
  }
}

export const getLastDay = (date) => {
  const firstDay = getFirstDay(date);
  const yearLength = getYearLength(date);

  return add(firstDay, {days: (yearLength * 7) - 1});
}


export const getDayOfWeek = (date) => {
  return getDay(date);
}

export const getDayOfTheYear = (date, currentDate) => {
  return differenceInDays(currentDate, date) + 1;
}

export const inYearBefore = (startDate, currentDate) => {
  return differenceInDays(currentDate, startDate) <= 0 && !isSameDay(currentDate, startDate);
}

export const inYearAfter = (startDate, currentDate) => {
  const currentYearLength = getYearLength(startDate);
  const firstDay = getFirstDay(startDate);
  const daysApart = differenceInDays(currentDate, firstDay);

  return currentYearLength * 7 < daysApart;
}

export const needsNewDate = (startDate, currentDate, newYear) => {
  const dateToChange = (newYear && startDate) || currentDate;
  const yearBefore = inYearBefore(startDate, currentDate);
  const yearAfter = inYearAfter(startDate, currentDate);
  let newDate;

  if((yearBefore && newYear) || (yearAfter && !newYear)) {
    newDate = toDate(sub(dateToChange, {years: 1}));
  } else if((yearBefore && !newYear) || (yearAfter && newYear)) {
    newDate = toDate(add(dateToChange, {years: 1}));
  }

  return newDate;
}
